import apollo from "@/plugins/apollo";
import { CREATE_PLACEMENT,GET_PLACEMENT_IN_CHANNEL, UPDATE_PLACEMENT,DELETE_PLACEMENT,GET_PLACEMENT_STATUS } from "@/graphql/Dashboard/Placement/Placement";

const createPlacement = async (content) => {
  let payload = { content: content }
  const response = await apollo.mutate({
    mutation: CREATE_PLACEMENT,
    variables: payload,
  });
  
  return response.data.createPlacement;
};

const updatePlacement = async (content) => {
  let payload = { content: content }
  const response = await apollo.mutate({
    mutation: UPDATE_PLACEMENT,
    variables: payload,
  });
  
  return response.data.updatePlacement;
};

const deletePlacement = async (content) => {
  let payload = { content: content }
  const response = await apollo.mutate({
    mutation: DELETE_PLACEMENT,
    variables: payload,
  });
  
  return response.data.deletePlacement;
};

const getPlacementStatus = async (variables) => {
  const response = await apollo.query({
    query: GET_PLACEMENT_STATUS,
    variables,
  });
  
  return response.data.placements_status;
};

const getPlacementInChannel = async (variables) => {
  const response = await apollo.query({
    query: GET_PLACEMENT_IN_CHANNEL,
    variables,
    fetchPolicy: 'no-cache',
  });
  
  return response.data.placements;
};

export { getPlacementInChannel,createPlacement, updatePlacement, deletePlacement, getPlacementStatus };
