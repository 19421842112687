import gql from 'graphql-tag'
export const UPDATE_ADP_CAMPAIGN = gql`
mutation Mutation($campaignId: Int!, $file: Upload!) {
  uploadAdpCampaign(campaign_id: $campaignId, file: $file) {
    code
    data
    message
    success
  }
}
`