import apollo from '@/plugins/apollo'
import { GET_TEAMS } from '@/graphql/Dashboard/Teams'
import { GET_USERS } from '@/graphql/Dashboard/Users/Users'
import { TEAM_VISUAL } from '@/graphql/Dashboard/TeamVisual'
import { DELETE_USER } from '@/graphql/Dashboard/Users/DeleteUser'
import { GET_ACCOUNTS } from '@/graphql/Dashboard/Accounts'
import { GET_ADVERTISERS } from '@/graphql/Dashboard/Advertisers'
import { REGISTER_USER } from '@/graphql/Dashboard/Users/RegisterUser'
import { UPDATE_USER } from '@/graphql/Dashboard/Users/UpdateUser'

const AccountsData = async (variables) => {
  const response = await apollo.query({
    query: GET_ACCOUNTS,
    variables
  })
  return response.data.accounts
}

const AdvertisersData = async (variables) => {
  const response = await apollo.query({
    query: GET_ADVERTISERS,
    variables
  })
  return response.data.advertisers
}
const TeamsData = async (variables) => {
  const response = await apollo.query({
    query: GET_TEAMS,
    variables
  })
  return response.data.teams
}

const getVisual = async (variables) => {
  const response = await apollo.query({
    query: TEAM_VISUAL,
    variables
  })
  return response.data.team
}

const UsersData = async (variables) => {
  const response = await apollo.query({
    query: GET_USERS,
    variables,
    fetchPolicy: 'network-only'
  })
  return response.data.users
}

const RegisterNewUser = async (content) => {
  try {
    const response = await apollo.mutate({
      mutation: REGISTER_USER,
      variables: content
    })
    return response
  } catch (error) {
    throw error;
  }
}

const EditUser = async (content) => {
  const response = await apollo.mutate({
    mutation: UPDATE_USER,
    variables: content
  })
  return response.data
}

const DeleteUser = async (content) => {
  const response = await apollo.mutate({
    mutation: DELETE_USER,
    variables: content
  })
  return response.data
}

export {
  RegisterNewUser,
  AccountsData,
  AdvertisersData,
  TeamsData,
  UsersData,
  getVisual,
  EditUser,
  DeleteUser
}
