import apollo from "@/plugins/apollo";
import { GET_SITES, GET_SITES_CAMPAIGN } from "@/graphql/Dashboard/Sites/Sites";
import { REGISTER_SITE } from "@/graphql/Dashboard/Sites/RegisterSite";
import { FIND_SITE } from "@/graphql/Dashboard/Sites/Site";
import { UPDATE_SITE } from "@/graphql/Dashboard/Sites/UpdateSite";

const getSites = async (variables) => {
  const response = await apollo.query({
    query: GET_SITES,
    variables,
  });
  
  return response.data.sites;
};

const getSitesInCampaign = async (variables) => {
  const response = await apollo.query({
    query: GET_SITES_CAMPAIGN,
    variables,
    fetchPolicy: 'no-cache',
  });
  
  return response.data.campaign.sites;
};

const registerSite = async (content) => {
  const response = await apollo.mutate({
    mutation: REGISTER_SITE,
    variables: content
  })
  return response.data.createSite
}

const updateSite = async (content) => {
  const response = await apollo.mutate({
    mutation: UPDATE_SITE,
    variables: content
  })
  
  return response.data.updateSite
}

const findSite = async (variables) => {
  const response = await apollo.mutate({
    mutation: FIND_SITE,
    variables
  })

  return response.data.site
}

export { getSites,getSitesInCampaign, registerSite, findSite, updateSite };
