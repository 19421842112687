import { AdvertisersData, AccountsData, TeamsData, getVisual } from '@/services'

const dashboard = {
  namespaced: true,
  state: {
    dataAdvertisers: null,
    dataAccounts: null,
    dataTeams: null,
    visualIdentity: null,
    search: null,
    teamSelect: null,
    loading: false
  },
  getters: {
    dataAdvertisers: (state) => state.dataAdvertisers,
    dataAccounts: (state) => state.dataAccounts,
    dataTeams: (state) => state.dataTeams,
    visualIdentity: (state) => state.visualIdentity,
    search: (state) => state.search,
    teamSelect: (state) => state.teamSelect,
    loading: (state) => state.loading
  },
  mutations: {
    SET_ADVERTISERS(state, payload) {
      state.dataAdvertisers = payload
    },
    SET_ACCOUNTS(state, payload) {
      state.dataAccounts = payload
    },
    SET_TEAMS(state, payload) {
      state.dataTeams = payload
    },
    SET_VISUAL_IDENTITY(state, payload) {
      state.visualIdentity = payload
    },
    SET_SEARCH(state, payload) {
      state.search = payload
    },
    SET_TEAM_SELECT(state, payload) {
      state.teamSelect = payload
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },
  },
  actions: {
    async getVisualIdentity({ commit }, variables) {
      const response = await getVisual(variables)
      commit('SET_VISUAL_IDENTITY', response)
    },
    async getAdvertisersData({ commit }, variables) {
      const response = await AdvertisersData(variables)
      commit('SET_ADVERTISERS', response)
    },
    async getAccountsData({ commit }, variables) {
      const response = await AccountsData(variables)
      commit('SET_ACCOUNTS', response)
    },
    async getTeamData({ commit }, variables) {
      const response = await TeamsData(variables)
      commit('SET_TEAMS', response)
    },
    async searchCampaigns({ commit }, payload) {
      commit("SET_SEARCH", payload);
    },
    async selectTeam({ commit }, payload) {
      commit("SET_TEAM_SELECT", payload);
    },
    async setLoading({ commit }, payload) {
      commit("SET_LOADING", payload);
    },
  }
}

export default dashboard
