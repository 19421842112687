export default {
  path: '/dashboard',
  component: () => import('@/views/Dashboard/Dashboard.vue'),
  meta: { requiresAuth: true },
  children: [
    {
      path: 'users',
      name: 'users',
      meta: { accessRoute: true },
      component: () => import('@/views/Dashboard/Users/Users.vue')
    },
    {
      path: 'advertisers',
      name: 'advertisers',
      meta: { accessRoute: true },
      component: () => import('@/views/Dashboard/Advertisers/Advertisers.vue')
    },
    {
      path: 'advertiser/:account_id?',
      name: 'advertiser',
      meta: { accessRoute: true },
      component: () => import('@/views/Dashboard/Advertisers/Create/index.vue')
    },
    {
      path: 'agencies',
      name: 'agencies',
      meta: { accessRoute: true },
      component: () => import('@/views/Dashboard/Agencys/Agencys.vue')
    },
    {
      path: 'agencie/:account_id?',
      name: 'agencie',
      meta: { accessRoute: true },
      component: () => import('@/views/Dashboard/Agencys/Create/index.vue')
    },
    {
      path: 'vehicles',
      name: 'vehicles',
      meta: { accessRoute: true },
      component: () => import('@/views/Dashboard/Vehicles/index.vue')
    },
    {
      path: 'vehicle/:site_id?',
      name: 'AddVehicle',
      meta: { accessRoute: true },
      component: () => import('@/views/Dashboard/Vehicles/Create/index.vue')
    },
    {
      path: 'campaigns',
      name: 'campaigns',
      meta: { accessRoute: true },
      component: () => import('@/views/Dashboard/Campaigns/index.vue')
    },
    {
      path: 'campaign/:campaign_id?',
      name: 'AddCampaign',
      meta: { accessRoute: true },
      component: () => import('@/views/Dashboard/Campaigns/Create/index.vue')
    }
  ]
}
