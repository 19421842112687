import gql from 'graphql-tag'

export const GET_TEAMS = gql`
  query Teams($filter: String) {
    teams(filter: $filter) {
      team_id
      team_name
    }
  }
`
