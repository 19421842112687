import Vue from 'vue'
import Vuex from 'vuex'

import advertiser from './modules/advertiser.module'
import agency from './modules/agency.module'
import auth from './modules/auth.module'
import contact from './modules/contact.module'
import dashboard from './modules/dashboard.module'
import format from './modules/format.module'
import modal from './modules/modal.module'
import users from './modules/users.module'
import vehicle from './modules/vehicle.module'
import campaign from './modules/campaign.module'
import fixedInputData from './modules/fixedInputData.module'
import channel from './modules/channel.module'
import placement from './modules/placement.module'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    dashboard,
    users,
    vehicle,
    modal,
    advertiser,
    agency,
    contact,
    format,
    campaign,
    fixedInputData,
    channel,
    placement
  }
})

export default store
