import gql from 'graphql-tag'

export const CREATE_CONTACT_SITE = gql`
  mutation Mutation($content: SiteContactContent!) {
    createSiteContact(content: $content) {
      code
      data
      message
      success
    }
  }
`
