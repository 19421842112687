import Vue from 'vue'
import Router from 'vue-router'
import {loggedUser} from '@/services/auth-service'
import AuthenticationRoutes from './auth'
import DashboardRoutes from './dashboard'

Vue.use(Router)

// Define a URL base para o serviço de análise
let url = window.location.hostname
if (
  (window.location.hostname === 'localhost' ||
  window.location.hostname === 'analytics-v4.adserver.dev' ||
  window.location.hostname === 'dev-admin-v4.00px.net')
) {
  url = 'admin.00px.net'
} else {
  url = window.location.hostname
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/login',
      name: 'Home',
      meta: { requiresAuth: false }
    },
    AuthenticationRoutes,
    DashboardRoutes
  ],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  try {
    //    const variables = { filter: `{ 'team_analytics_url' : '${url}' }` }
    //  const response = await TeamsData(variables)
    // router.app.$store.dispatch('auth/getTeamData', response[0])
    document.title = `00px | Admin`
  } catch (error) {}

  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (to.query.s) {
      localStorage.setItem('sessionId', to.query.s)
    }

    const loginRoute = {
      path: '/login',
      query: { redirect: to.fullPath }
    }

    if (window.localStorage.sessionId) {
      try {
        router.app.$store.dispatch('dashboard/setLoading', true)
        const user = await loggedUser({ fetchPolicy: 'network-only' })
        router.app.$store.dispatch('auth/getUser', user)
        window.localStorage.setItem('redirect', user.redirect)
        
        if (to.matched.some((route) => route.meta.accessRoute)) {
          if (user.redirect !== null) {
            return next(from.path)
          } else {
            return next()
          }
        }
        
        return next()
      } catch (error) {
        return next(loginRoute)
      } finally{
        router.app.$store.dispatch('dashboard/setLoading', false)
      }
    }
    
    return next(loginRoute)
  }

  return next()
})

export default router
