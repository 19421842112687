import gql from 'graphql-tag'

export const GET_ADVERTISERS = gql`
query Advertisers($limit: Int, $offset: Int, $orderby: String) {
  advertisers(limit: $limit, offset: $offset, orderby: $orderby) {
    account_name
    account_id
  }
}
`
