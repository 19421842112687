import gql from 'graphql-tag'

export const INSERT_CODE = gql`
mutation Login($userEmail: String!, $code: String) {
  login(user_email: $userEmail, code: $code) {
    code
    data
    message
  }
}`
