import gql from 'graphql-tag'
export const GET_CONTACTS_GROUPS = gql`
  query Query($filter: String) {
    site(filter: $filter) {
      sites_contacts_groups {
        contact_group_is_default
        contact_group_name
        site_contact_group_id
        site_id
        team_id
      }
    }
  }
`
