import gql from 'graphql-tag'

export const FIND_SITE = gql`
  query Site($filter: String) {
    site(filter: $filter) {
      site_domains
      site_id
      site_active
      site_name
      site_cnpj
      site_type_id
      site_website
      site_mediakit_url
      site_adserver_macro
      parent_json
      formats {
        format_id
        format_name
        format_size_height
        format_size_width
        format_type_id
        format_modified_on
        format_type_id
        format_obs
        format_size_expanded_height
        format_size_expanded_width
        tag_id
      }
      metadata
      sites_contacts {
        site_id
        contact_email
        contact_name
        contact_obs
        site_contact_id
        contact_groups_ids
      }
      sites_contacts_groups {
        contact_group_name
        site_contact_group_id
        contact_group_is_default
      }
    }
  }
`
