import { UsersData } from '@/services'
const users = {
  namespaced: true,
  state: {
    dataTableUsers: null,
    dataRegisterUser: null
  },
  getters: {
    dataRegisterUser: (state) => state.dataRegisterUser,
    dataTableUsers: (state) => state.dataTableUsers
  },
  mutations: {
    SET_TABLE_USER(state, payload) {
      state.dataTableUsers = payload
    },
    SET_REGISTER_EDIT_USER(state, payload) {
      state.dataRegisterUser = payload
    }
  },
  actions: {
    async getDataTableUsers({ commit }, data) {
      const response = await UsersData(data)
      commit('SET_TABLE_USER', response)
    },
    registerUser({ commit }, data) {
      let user
      if (!data) {
        user = {
          user_name: '',
          user_email: '',
          advertiser: null,
          account: null,
          team: null,
          user_active: false,
          user_admin_access: false,
          user_analytics_access: false,
          user_business_access: false
        }
      } else {
        user = data
      }
      alert(JSON.stringify(user))
      commit('SET_REGISTER_EDIT_USER', user)
    }
  }
}

export default users
