import gql from 'graphql-tag'

export const UPDATE_CONTACT_SITE = gql`
mutation UpdateSiteContact($content: SiteContactContent!) {
  updateSiteContact(content: $content) {
    code
    data
    message
    success
  }
}`