import gql from 'graphql-tag'

export const GET_ACCOUNTS = gql`
query Accounts($offset: Int, $limit: Int, $orderby: String) {
  accounts(offset: $offset, limit: $limit, orderby: $orderby) {
    account_id
    account_name
  }
}
`
