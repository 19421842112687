import gql from 'graphql-tag'
export const GET_CAMPAIGNS = gql`
query Query($limit: Int, $offset: Int, $direction: String, $orderby: String, $filter: String) {
  campaigns(limit: $limit, offset: $offset, direction: $direction, orderby: $orderby, filter: $filter) {
    campaign_id
    campaign_name
    campaign_emails
    campaign_description
    account {
      account_id
      account_name
    }
    campaign_end_datetime
    campaign_start_datetime
    advertiser {
      account_id
      account_name
    }
    team {
      team_id
      team_name
    }
    campaign_status {
      campaign_status_id
      status_descr
    }
  }
}`