import apollo from "@/plugins/apollo";
import { GET_PURCHASE_TYPES } from "@/graphql/Dashboard/PurchaseType/PurchaseType";

const getPurchaseTypes = async (variables) => {
  const response = await apollo.query({
    query: GET_PURCHASE_TYPES,
    variables,
  });
  
  return response.data.purchases_types;
};

export { getPurchaseTypes };
