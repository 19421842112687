import gql from 'graphql-tag'

export const CREATE_CONTACT_GROUP_ACCOUNT = gql`
mutation CreateAccountContactsGroup($content: AccountContactsGroupContent!) {
  createAccountContactsGroup(content: $content) {
    code
    data
    message
    success
  }
}`