import gql from 'graphql-tag'

export const LOGGED_USER = gql`
query Logged_user {
  logged_user {
    user_id
    team_id
    account_id
    campaign_id
    site_id
    user_name
    user_title
    user_email
    user_phone
    user_obs
    user_role
    user_active
    user_admin_access
    user_analytics_access
    user_code
    user_code_timestamp
    redirect
    team {
      team_id
      team_name
    }
  }
}`