import {
  registerContactAdvertiser,
  registerContactSite,
  registerContactAccount,
  registerContactGroupAccount,
  registerContactGroupAdvertiser,
  registerContactGroupSite,
  updateContactAdvertiser,
  updateContactAccount,
  updateContactSite,
  deleteContactAdvertiser,
  deleteContactSite,
  deleteContactAccount,
  fetchContactsGroups,
  updateContactGroup,
  deleteContactGroup,
  fetchContacts
} from '@/services/contact.service'

export default {
  namespaced: true,
  state: {
    contacts: [],
    groupsContacts: [],
    contact: {},
    idProperty: {}
  },
  getters: {
    contacts: (state) => state.contacts,
    groupsContacts: (state) => state.groupsContacts,
    contact: (state) => state.contact,
    idProperty: (state) => state.idProperty
  },
  mutations: {
    SET_CONTACT(state, payload) {
      state.contact = payload
    },

    SET_ID_PROPERTY(state, payload) {
      state.idProperty = payload
    },

    UPDATE_CONTACT(state, payload) {
      let index
      if (state.contacts[0].hasOwnProperty('site_id')) {
        index = state.contacts.findIndex((task) => task.site_contact_id === payload.site_contact_id)
      } else {
        index = state.contacts.findIndex(
          (task) => task.account_contact_id === payload.account_contact_id
        )
      }

      if (index !== -1) {
        state.contacts.splice(index, 1)
        state.contacts.push(payload)
      }
    },

    DELETE_CONTACT(state, payload) {
      let index
      if (state.contacts[0].hasOwnProperty('site_id')) {
        index = state.contacts.findIndex((task) => task.site_contact_id === payload.site_contact_id)
      } else {
        index = state.contacts.findIndex(
          (task) => task.account_contact_id === payload.account_contact_id
        )
      }
      if (index !== -1) {
        state.contacts.splice(index, 1)
      }
    },

    SET_CONTACTS(state, payload) {
      state.contacts = payload
    },
    PUSH_CONTACT(state, payload) {
      state.contacts.push(payload)
    },

    GET_CONTACTS(state, payload) {
      state.contacts = payload
    },

    SET_GROUPS_CONTACTS(state, payload) {
      state.groupsContacts = payload
    },

    ADD_GROUPS_CONTACTS(state, payload) {
      state.groupsContacts.push(payload)
    },

    UPDATE_GROUP_CONTACT(state, payload) {
      state.groupsContacts.push(payload)
    },

    DELETE_GROUP_CONTACT(state, payload) {
      state.groupsContacts.push(payload)
    },

    CREATE_CONTACTS(state, payload) {
      state.contacts.push(payload)
    },

    GET_CONTACTS_GROUPS(state, payload) {
      state.groupsContacts = payload
    }
  },
  actions: {
    async store({ commit }, { type, content }) {
      var res = null
      if (type == 'advertiser') {
        res = await registerContactAdvertiser(content)
      } else if (type == 'site') {
        res = await registerContactSite(content)
      } else {
        res = await registerContactAccount(content)
      }
      commit('CREATE_CONTACTS', res)
      return res
    },

    async update({ commit }, { type, content }) {
      var res = null
      if (type == 'advertiser') {
        res = await updateContactAdvertiser(content)
      } else if (type == 'site') {
        res = await updateContactSite(content)
      } else {
        res = await updateContactAccount(content)
      }

      commit('UPDATE_CONTACT', res)
    },

    async delete({ commit }, { type, content }) {
      var res = null
      if (type == 'advertiser') {
        res = await deleteContactAdvertiser(content)
      } else if (type == 'site') {
        res = await deleteContactSite(content)
      } else {
        res = await deleteContactAccount(content)
      }

      if (res.success) {
        commit('DELETE_CONTACT', content)
      }
    },

    async setContacts({ commit }, data) {
      commit('SET_CONTACTS', data)
    },

    async addContact({ commit }, data) {
      commit('PUSH_CONTACT', data)
    },

    async getContacts({ commit }, { type, content }) {
      var res = null
      if (type == 'advertiser') {
        //
        // res.id = res.account_contact_group_id
      } else if (type == 'site') {
        res = await fetchContacts(content)
      } else {
        // res = await registerContactGroupAccount(content)
        // res.id = res.account_contact_group_id
      }
      commit('GET_CONTACTS', res)
      return res
    },

    async setGroupsContacts({ commit }, data) {
      commit('SET_GROUPS_CONTACTS', data)
    },

    async setHasId({ commit }, data) {
      commit('SET_ID_PROPERTY', data)
    },

    async getContactsGroups({ commit }, { type, content }) {
      var res = null
      if (type == 'advertiser') {
        //
        // res.id = res.account_contact_group_id
      } else if (type == 'site') {
        res = await fetchContactsGroups(content)
        res.id = res.site_contact_group_id
      } else {
        // res = await registerContactGroupAccount(content)
        // res.id = res.account_contact_group_id
      }
      commit('GET_CONTACTS_GROUPS', res)
      return res
    },

    async updateContactGroup({ commit }, { type, content }) {
      var res = null
      if (type == 'advertiser') {
        // res = await registerContactGroupAdvertiser(content)
        res.id = res.account_contact_group_id
      } else if (type == 'site') {
        res = await updateContactGroup(content)
        res.id = res.site_contact_group_id
      } else {
        // res = await registerContactGroupAccount(content)
        res.id = res.account_contact_group_id
      }

      commit('UPDATE_GROUP_CONTACT', res)
      return res
    },

    async deleteContactGroup({ commit }, { type, content }) {
      var res = null
      if (type == 'advertiser') {
        // res = await registerContactGroupAdvertiser(content)
        res.id = res.account_contact_group_id
      } else if (type == 'site') {
        res = await deleteContactGroup(content)
        res.id = res.site_contact_group_id
      } else {
        // res = await registerContactGroupAccount(content)
        res.id = res.account_contact_group_id
      }

      commit('DELETE_GROUP_CONTACT', res)
      return res
    },

    async storeGroup({ commit }, { type, content }) {
      var res = null
      if (type == 'advertiser') {
        res = await registerContactGroupAdvertiser(content)
        res.id = res.account_contact_group_id
      } else if (type == 'site') {
        res = await registerContactGroupSite(content)
        res.id = res.site_contact_group_id
      } else {
        res = await registerContactGroupAccount(content)
        res.id = res.account_contact_group_id
      }

      commit('ADD_GROUPS_CONTACTS', res)
      return res
    }
  }
}
