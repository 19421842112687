export default {
  namespaced: true,
  state: {
    dataModal: null,
  },
  getters: {
    dataModal: (state) => state.dataModal,
  },
  mutations: {
    SET_REGISTER_EDIT(state, payload) {
      state.dataModal = payload
    },
    SET_EDIT(state, payload) {
      state.dataModal = payload
    }
  },

  actions: {
    register({ commit }, data) {
      commit('SET_REGISTER_EDIT', data)
    },
    
    editModal({ commit }, data) {
      commit('SET_EDIT', data)
    }
  }
}