import apollo from "@/plugins/apollo";
import { GET_ACCOUNTS } from "@/graphql/Dashboard/Agencys/Agencys";
import { GET_AGENCY } from "@/graphql/Dashboard/Agencys/Agency";
import { REGISTER_AGENCY } from "@/graphql/Dashboard/Agencys/RegisterAgency";
import { UPDATE_AGENCY } from "@/graphql/Dashboard/Agencys/UpdateAgencys";

const getAgencys = async (variables) => {
  const response = await apollo.query({
    query: GET_ACCOUNTS,
    variables,
  });
  
  return response.data.accounts;
};

const registerAgency = async (content) => {
  const response = await apollo.mutate({
    mutation: REGISTER_AGENCY,
    variables: content
  })
  
  return response.data.createAccount
}

const updateAgency = async (content) => {
  const response = await apollo.mutate({
    mutation: UPDATE_AGENCY,
    variables: content
  })
  
  return response.data.updateAccount
}

const findAgency = async (variables) => {
  const response = await apollo.mutate({
    mutation: GET_AGENCY,
    variables
  })
  
  return response.data.account
}

export { getAgencys, findAgency, registerAgency, updateAgency };
