import gql from 'graphql-tag'

export const CREATE_CONTACT_ADVERTISER = gql`
mutation Mutation($content: AdvertiserContactContent!) {
  createAdvertiserContact(content: $content) {
    data
    code
    message
    success
  }
}`