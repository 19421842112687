import gql from 'graphql-tag'

export const DELETE_CONTACT_ADVERTISER = gql`
mutation DeleteAdvertiserContact($content: AdvertiserContactContent!) {
  deleteAdvertiserContact(content: $content) {
    code
    data
    message
    success
  }
}`