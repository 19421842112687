import gql from 'graphql-tag'
export const DELETE_CONTACT_GROUP = gql`
  mutation DeleteSiteContactsGroup($content: SiteContactsGroupContent!) {
    deleteSiteContactsGroup(content: $content) {
      code
      data
      message
      success
    }
  }
`
