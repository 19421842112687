import gql from 'graphql-tag'
export const FIND_CAMPAIGN = gql`
query Campaign($filter: String, $creativesGroupsFilter2: String, $orderby: String, $direction: String) {
  campaign(filter: $filter) {
    campaign_id
    campaign_name
    campaign_emails
    team_id
    account {
      account_id
      account_name
      accounts_contacts {
        contact_name
        contact_email
        contact_groups_ids
      }
      accounts_contacts_groups {
        account_contact_group_id
        contact_group_name
      }
    }
    campaign_end_datetime
    campaign_start_datetime
    advertiser {
      account_id
      account_name
      accounts_contacts {
        contact_name
        contact_email
        contact_groups_ids
      }
      accounts_contacts_groups {
        account_contact_group_id
        contact_group_name
      }
    }
    campaign_delivery_limit_check
    campaign_active_alerts
    campaign_block_safeframes
    campaign_created_at
    campaign_redirect_url
    campaign_regions
    creatives_groups(filter: $creativesGroupsFilter2, orderby: $orderby, direction: $direction) {
      group_name
      creative_group_id
      creative_group_deleted_at
      creatives {
        creative_id
      }
    }
    campaign_delivery_limit_ratio
    campaign_filter_keywords_blacklist
    campaign_description
    campaign_reference
    campaign_locations
  }
}
`