import moment from 'moment'

function convertDate(input) {
  if (!isNaN(input)) {
    return moment.unix(input / 1000).format('DD/MM/YYYY')
  } else if (moment(input, 'DD/MM/YYYY', true).isValid()) {
    return moment(input, 'DD/MM/YYYY').valueOf()
  } else {
    throw new Error(
      'Formato de data inválido. Use um timestamp Unix ou data no formato DD/MM/AAAA.'
    )
  }
}

const formatError = (message) => {
  const messageSplit = message.split(':')
  return messageSplit[messageSplit.length - 1].trim()
}

const formatDate = (data, oldFormat, newFormat) => {
  if(data){
    return moment(data,oldFormat).format(newFormat)
  } else{
    return '-'
  }
}

const toUpperCaseFunction = (str) => {
  if (!str) {
    return ''
  } else {
    return str.toUpperCase()
  }
}

const camelize = (str) => {
  if (typeof str !== 'undefined' && str) {
    if (str.length > 4) {
      return str.replace(/^[a-z]|[A-Z]|[A-zÀ-ÖØ-öø-ÿ]/g, function (v, i) {
        let chr = i === 0 ? v.toUpperCase() : ' ' + v.toLowerCase()
        return chr.trim()
      })
    } else {
      return str.replace(/^[a-z]|[A-Z]|[A-zÀ-ÖØ-öø-ÿ]/g, function (v, i) {
        let chr = i === 0 ? v.toUpperCase() : ' ' + v.toUpperCase()
        return chr.trim()
      })
    }
  } else {
    return str
  }
}

const formatNumber = (value) => {
  if (!value) {
    return 0
  } else {
    return parseInt(value).toLocaleString('pt-br')
  }
}

const truncate = (str, length) => {
  if (str && str.length > length) {
    return str.substring(0, length) + '...'
  } else {
    return str
  }
}

const formatToFloat = (number) => {
  // Verifica se o valor é um número diretamente
  if (typeof number === 'number') {
    return number;
  }

  // Verifica se o valor é uma string
  if (typeof number === 'string') {
    // Remove os pontos de separadores de milhar
    let noThousandSeparators = number.replace(/\./g, '');
  
    // Substitui a vírgula decimal por um ponto decimal
    let withDecimalPoint = noThousandSeparators.replace(',', '.');
  
    // Converte a string resultante em um número float
    let floatNumber = parseFloat(withDecimalPoint);
  
    return floatNumber;
  }

  // Caso o valor não seja um número nem uma string
  return NaN;
};
export { formatDate, formatError,formatToFloat, camelize, formatNumber, truncate, toUpperCaseFunction, convertDate }
