import gql from 'graphql-tag'
export const GET_ACCOUNTS = gql`
query Accounts($filter: String, $orderby: String, $direction: String, $limit: Int) {
  accounts(filter: $filter, orderby: $orderby, direction: $direction, limit: $limit) {
    account_id
    account_name
    accounts_contacts {
      account_id
      contact_code
      contact_email
    }
  }
}`