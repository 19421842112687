import gql from 'graphql-tag'

export const GET_CREATIVES_GROUPS = gql`
query Creatives_groups($filter: String) {
  campaigns(filter: $filter) {
    creatives_groups {
      campaign_id
      group_name
      creative_group_id
      creative_group_deleted_at
    }
  }
}
`

export const GET_CREATIVES_IN_CHANNEL = gql`
query Creatives($filter: String, $limit: Int) {
  creatives(filter: $filter, limit: $limit) {
    placement_id
    creative_id
    creative_active
    creative_dates
    creative_redirect_url
    creative_delivery_order
    creative_delivery_limit
    creative_status_id
    creative_group {
      creative_group_id
      group_name
      campaign_id
    }
    placement {
      creatives {
        campaign_id
      }
    }
    total_data {
      clicks
      impressions
      views
    }
  }
}
`

export const CREATE_CREATIVE_GROUP = gql`
mutation CreateCreativeGroup($content: CreativeGroupContent!) {
  createCreativeGroup(content: $content) {
    code
    data
    message
    success
  }
}
`

export const UPDATE_CREATIVE_GROUP = gql`
mutation UpdateCreativeGroup($content: CreativeGroupContent!) {
  updateCreativeGroup(content: $content) {
    code
    data
    message
    success
  }
}
`

export const CREATE_CREATIVE = gql`
mutation CREATE_CREATIVE($content: CreativeContent!) {
  createCreative(content: $content) {
    code
    data
    message
    success
  }
}
`

export const UPDATE_CREATIVE = gql`
mutation Mutation($content: CreativeContent!) {
  updateCreative(content: $content) {
    code
    data
    message
    success
  }
}
`

export const DELETE_CREATIVE = gql`
mutation Mutation($content: CreativeContent!) {
  deleteCreative(content: $content) {
    code
    data
    message
    success
  }
}
`
