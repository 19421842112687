import gql from 'graphql-tag'

export const DELETE_CONTACT_SITE = gql`
mutation DeleteSiteContact($content: SiteContactContent!) {
  deleteSiteContact(content: $content) {
    code
    data
    message
    success
  }
}`