import gql from 'graphql-tag'
export const DELETE_USER = gql`
mutation DeleteUser($content: UserContent!) {
    deleteUser(content: $content) {
      code
      message
      success
      data {
        user_name
        user_id
      }
    }
  }`