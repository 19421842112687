import gql from 'graphql-tag'
export const GET_CONTACTS = gql`
  query Site($filter: String) {
    site(filter: $filter) {
      sites_contacts {
        contact_name
        contact_email
        contact_groups_ids
      }
    }
  }
`
