import gql from 'graphql-tag'

export const GET_PURCHASE_TYPES = gql`
query Purchases_types {
  purchases_types {
    purchase_type_id
    purchase_type_name
    purchase_type_descr
    purchase_type_format
    purchase_type_field
  }
}
`
