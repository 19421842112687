import gql from 'graphql-tag'
export const GET_USERS = gql`
query Users($limit: Int, $filter: String) {
  users(limit: $limit, filter: $filter) {
    user_id
    user_name
    user_email
    user_active
    user_admin_access
    user_business_access
    user_analytics_access
    advertiser {
      account_id
      account_name
    }
    account {
      account_id
      account_name
    }
    team {
      team_id
      team_name
    }
  }
}`