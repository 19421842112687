import apollo from "@/plugins/apollo";
import { GET_ADVERTISERS } from "@/graphql/Dashboard/Advertisers/Advertisers";
import { GET_ADVERTISER } from "@/graphql/Dashboard/Advertisers/Advertiser";
import { REGISTER_ADVERTISER } from "@/graphql/Dashboard/Advertisers/RegisterAdvertiser";
import { UPDATE_ADVERTISER } from "@/graphql/Dashboard/Advertisers/UpdateAdvertiser";

const getAdvertisers = async (variables) => {
  const response = await apollo.query({
    query: GET_ADVERTISERS,
    variables,
  });

  return response.data.advertisers;
};

const registerAdvertiser = async (content) => {
  const response = await apollo.mutate({
    mutation: REGISTER_ADVERTISER,
    variables: content
  })

  return response.data.createAdvertiser
}

const updateAdvertiser = async (content) => {
  const response = await apollo.mutate({
    mutation: UPDATE_ADVERTISER,
    variables: content
  })

  return response.data.updateAdvertiser
}

const findAdvertiser = async (variables) => {
  const response = await apollo.query({
    query: GET_ADVERTISER,
    variables
  })
  
  return response.data.advertiser
}

export { getAdvertisers, registerAdvertiser, updateAdvertiser, findAdvertiser };
