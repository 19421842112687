import gql from 'graphql-tag'
export const UPDATE_CONTACT_GROUP = gql`
  mutation Mutation($content: SiteContactsGroupContent!) {
    updateSiteContactsGroup(content: $content) {
      code
      data
      message
      success
    }
  }
`
