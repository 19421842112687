import gql from 'graphql-tag'
export const GET_AGENCY = gql`
query Query($filter: String) {
  account(filter: $filter) {
    account_api_token
    account_cnpj
    account_cpf
    account_name
    metadata
    accounts_contacts {
      account_contact_id
      account_id
      contact_email
      contact_name
      contact_phone
      contact_groups_ids
    }
    accounts_contacts_groups {
      contact_group_name
      account_contact_group_id
    }
    account_id
  }
}`