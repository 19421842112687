export default {
    path: '',
    component: () => import('@/views/Auth/Authentication.vue'),
    meta: { requiresAuth: false },
    children: [
        {
            path: 'login',
            name: 'Login',
            component: () => import('@/components/Auth/Login/Login.vue')
        },
        {
            path: 'send-code',
            name: 'SendCode',
            component: () => import('@/components/Auth/SendCode/SendCode.vue')
        },
        {
            path: 'insert-code',
            name: 'InsertCode',
            component: () => import('@/components/Auth/InsertCode/InsertCode.vue')
        },
        {
            path: 'recover-password',
            name: 'RecoverPassword',
            component: () => import('@/components/Auth/RecoverPassword/RecoverPassword.vue')
        }
    ]
};
