import gql from 'graphql-tag'

export const DELETE_CONTACT_ACCOUNT = gql`
mutation DeleteAccountContact($content: AccountContactContent!) {
  deleteAccountContact(content: $content) {
    code
    data
    message
    success
  }
}`