import gql from 'graphql-tag'

export const GET_CHANNELS = gql`
query GET_CHANNELS($filter: String) {
  channels(filter: $filter) {
    channel_id
    channel_descr
    channel_pi_number
    channel_purchased_quantity
    channel_amount_invested
    channel_delivery_limit_quantity
    placements {
      placement_id
      placement_filter_domains_blacklist
    }
  }
}
`

export const GET_CHANNELS_IN_SITE = gql`
query Channels($filter: String, $limit: Int) {
  channels(filter: $filter, limit: $limit) {
    channel_id
    site_id
    channel_descr
    channel_pi_number
    channel_purchased_quantity
    channel_amount_invested
    channel_delivery_limit_quantity
    total_data {
      clicks
      impressions
      views
    }
  }
}
`

export const GET_CHANNELS_IN_CAMPAIGN = gql`
query GET_CHANNELS_IN_CAMPAIGN($filter: String, $campaignId: Int) {
  site(filter: $filter) {
    channels(campaign_id: $campaignId) {
      channel_id
      channel_descr
      channel_purchased_quantity
      channel_amount_invested
      channel_pi_number
    }
  }
}
`

export const CREATE_CHANNEL = gql`
mutation CREATE_CHANNEL($content: ChannelContent!) {
  createChannel(content: $content) {
    code
    data
    message
    success
  }
}
`

export const UPDATE_CHANNEL = gql`
mutation UPDATE_CHANNEL($content: ChannelContent!) {
  updateChannel(content: $content) {
    code
    data
    message
    success
  }
}
`
