import apollo from "@/plugins/apollo";
import { GET_FORMATS } from "@/graphql/Dashboard/Formats/Formats";
import { REGISTER_FORMAT } from "@/graphql/Dashboard/Formats/RegisterFormat";
import { UPDATE_FORMAT } from "@/graphql/Dashboard/Formats/UpdateFormat";
import { DELETE_FORMAT } from "@/graphql/Dashboard/Formats/DeleteFormat";
import { GET_TAGS } from "@/graphql/Dashboard/Tags/Tags";

const registerFormat = async (content) => {
  const response = await apollo.mutate({
    mutation: REGISTER_FORMAT,
    variables: content
  })

  return response.data.createFormat.data
}

const updateFormat = async (content) => {
  const response = await apollo.mutate({
    mutation: UPDATE_FORMAT,
    variables: content
  })

  return response.data.updateFormat.data
}

const deleteFormat = async (content) => {
  const response = await apollo.mutate({
    mutation: DELETE_FORMAT,
    variables: content
  })

  return response.data.deleteFormat
}

const getFormats = async (variables) => {
  const response = await apollo.query({
    query: GET_FORMATS,
    variables
  })

  return response.data.formats
}

const getTags = async (variables) => {
  const response = await apollo.query({
    query: GET_TAGS,
    variables
  })

  return response.data.tags
}


export { registerFormat, updateFormat, deleteFormat, getTags, getFormats };
