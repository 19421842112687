import { mapGetters } from "vuex"

export default {
    name: "Footer",
  
    data: () => ({
    }),
    computed: {
      ...mapGetters("dashboard", ["visualIdentity"]),
    }
  };