import { createPlacement, updatePlacement, deletePlacement, getPlacementStatus } from "@/services/placement.service";

export default {
	namespaced: true,
    state: {
        placements: [],
        placement: {},
        statusPlacements: [],
    },
    getters: {
		placements: (state) => state.placements,
		placement: (state) => state.placement,
		statusPlacements: (state) => state.statusPlacements,
	},
    mutations: {
        SET_PLACEMENT(state, payload){
            state.placement = payload
        },

        SET_PLACEMENTS(state, payload){
            state.placements = payload
        },

        UPDATE_PLACEMENT(state, payload){
            const index = state.placements.findIndex(task => task.id === payload.id);
            if (index !== -1) {
                state.placements[index] = payload;
            }
        },
        DELETE_PLACEMENT(state, payload){
            const index = state.placements.filter(task => task.id === payload.id);
            if (index !== -1) {
                state.placements[index] = payload;
            }
        },

        CREATE_PLACEMENTS(state, payload){
            state.placements.push(payload)
        },

        SET_STATUS_PLACEMENT(state, payload){
            state.statusPlacements = payload
        },
    },
    actions: {
        async getPlacements({ commit }, data){
            const res = await getPlacements(data)
            
            commit("SET_PLACEMENTS", res)
        },

        async storePlacement({ commit }, data){
            const res = await createPlacement(data)
            
            commit("CREATE_PLACEMENTS", res)
            return res
        },

        async updatePlacement({ commit }, data){
            const res = await updatePlacement(data)

            commit("UPDATE_PLACEMENT", res)
            return res
        },

        async deletePlacement({ commit }, data){
            const res = await deletePlacement(data)

            commit("DELETE_PLACEMENT", res)
            return res
        },

        async getStatusPlacement({ commit }, data){
            const res = await getPlacementStatus(data)

            commit("SET_STATUS_PLACEMENT", res)
            return res
        },

        async findPlacement({ commit }, data){
            const res = await getPlacements(data)
            commit("SET_PLACEMENT", res[0])
        }
    },
}

