import gql from 'graphql-tag'

export const UPDATE_CONTACT_ADVERTISER = gql`
mutation UpdateAdvertiserContact($content: AdvertiserContactContent!) {
  updateAdvertiserContact(content: $content) {
    code
    data
    message
    success
  }
}`