import { registerFormat, getFormats, updateFormat, deleteFormat, getTags } from "@/services/format.service"

export default {
	namespaced: true,
    state: {
        formats: [],
        tags: [],
        format: {},
    },
    getters: {
		formats: (state) => state.formats,
		tags: (state) => state.tags,
		format: (state) => state.format,
	},
    mutations: {
        SET_FORMAT(state, payload){
            state.format = payload
        },

        UPDATE_FORMAT(state, payload){
            const index = state.formats.findIndex(task => task.id === payload.id);
            if (index !== -1) {
                state.formats[index] = payload;
            }
        },

        DELETE_FORMAT(state, payload){
            const index = state.formats.findIndex(task => task.id === payload.id);
            if (index !== -1) {
                state.formats[index] = payload;
            }
        },

        SET_FORMATS(state, payload){
            state.formats = payload
        },

        SET_TAGS(state, payload){
            state.tags = payload
        },

        CREATE_FORMATS(state, payload){
            state.formats.push(payload)
        },
    },
    actions: {
        async store({ commit }, data){
            const res = await registerFormat(data)
            
            commit("CREATE_FORMATS", res)
            commit("SET_FORMAT", res)
            return res
        },

        async update({ commit }, data){
            const res = await updateFormat(data)

            commit("UPDATE_FORMAT", res)
            return res
        },

        async delete({ commit }, data){
            const res = await deleteFormat(data)

            commit("DELETE_FORMAT", res)
            return res
        },

        async getFormats({ commit }, data){
            const res = await getFormats(data)
            commit("SET_FORMATS", res)
        },

        async getAllTags({ commit }, data){
            const res = await getTags()
            commit("SET_TAGS", res)
        },
    },
    
}

