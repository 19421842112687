import gql from 'graphql-tag'

export const CREATE_CONTACT_GROUP_ADVERTISER = gql`
mutation CreateAdvertiserContactsGroup($content: AdvertiserContactsGroupContent!) {
  createAdvertiserContactsGroup(content: $content) {
    code
    data
    message
    success
  }
}`