import { getAdvertisers, registerAdvertiser, findAdvertiser, updateAdvertiser } from "@/services/advertiser.service"

export default {
	namespaced: true,
    state: {
        advertisers: [],
        advertiser: {},
    },
    getters: {
		advertisers: (state) => state.advertisers,
		advertiser: (state) => state.advertiser,
	},
    mutations: {
        SET_ADVERTISER(state, payload){
            state.advertiser = payload
        },

        UPDATE_ADVERTISER(state, payload){
            const index = state.advertisers.findIndex(task => task.id === payload.id);
            if (index !== -1) {
                state.advertisers[index] = payload;
            }
        },

        SET_ADVERTISERS(state, payload){
            state.advertisers = payload
        },
        CREATE_ADVERTISERS(state, payload){
            state.advertisers.push(payload)
        },
    },
    actions: {
        async getAdvertisers({ commit }, data){
            /* 
                TO-DO
                verificar se realmete é preciso paginar pois hj chamamos todos
            */
            var variables = {
                limit: -1,
                /* orderby: "account_id",
                direction: "desc" */
            }
            const res = await getAdvertisers(data)
            
            const result = res.map(item =>{
                return {
                    account_id: item?.account_id,
                    account_name: item?.account_name,
                    contacts: item?.accounts_contacts?.length,
                }
            })
            
            commit("SET_ADVERTISERS", result)
        },

        async store({ commit }, data){
            const res = await registerAdvertiser(data)
            
            commit("CREATE_ADVERTISERS", res)
            commit("SET_ADVERTISER", res)
            return res
        },

        async update({ commit }, data){
            const res = await updateAdvertiser(data)
            
            commit("UPDATE_ADVERTISER", res)
        },
    
        async find({ dispatch, commit }, data){
            var variables = {
                filter: `{ 'account_id': ${parseInt(data)}}`
            }
            const res = await findAdvertiser(variables)
            dispatch('contact/setHasId',{account_id:res.account_id}, { root: true })
            dispatch('contact/setContacts', res.accounts_contacts, { root: true })
            dispatch('contact/setGroupsContacts', res.accounts_contacts_groups.map(i=> 
                {
                    return {...i,
                group_id: i.account_contact_group_id}
            }), { root: true })
            commit("SET_ADVERTISER", res)
        }
    },
    
}

