import gql from 'graphql-tag'
export const GET_ADVERTISER = gql`
query Query($filter: String) {
  advertiser(filter: $filter) {
    account_api_token
    account_cnpj
    account_id
    account_name
    account_type_id
    account_cpf
    account_website
    accounts_contacts {
      account_id
      contact_email
      contact_name
      contact_groups_ids
    }
    accounts_contacts_groups {
      contact_group_name
      account_contact_group_id
    }
    team_id
    metadata
  }
}`