import gql from 'graphql-tag'

export const CREATE_CONTACT_GROUP_SITE = gql`
mutation CreateSiteContactsGroup($content: SiteContactsGroupContent!) {
  createSiteContactsGroup(content: $content) {
    code
    data
    message
    success
  }
}`