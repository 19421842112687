const fixedInputData = {
  namespaced: true,
  state: {
    regions: {
      Norte: [
        { nome: "Acre", sigla: "AC" },
        { nome: "Amapá", sigla: "AP" },
        { nome: "Amazonas", sigla: "AM" },
        { nome: "Pará", sigla: "PA" },
        { nome: "Rondônia", sigla: "RO" },
        { nome: "Roraima", sigla: "RR" },
        { nome: "Tocantins", sigla: "TO" },
      ],
      Nordeste: [
        { nome: "Alagoas", sigla: "AL" },
        { nome: "Bahia", sigla: "BA" },
        { nome: "Ceará", sigla: "CE" },
        { nome: "Maranhão", sigla: "MA" },
        { nome: "Paraíba", sigla: "PB" },
        { nome: "Pernambuco", sigla: "PE" },
        { nome: "Piauí", sigla: "PI" },
        { nome: "Rio Grande do Norte", sigla: "RN" },
        { nome: "Sergipe", sigla: "SE" },
      ],
      "Centro-Oeste": [
        { nome: "Distrito Federal", sigla: "DF" },
        { nome: "Goiás", sigla: "GO" },
        { nome: "Mato Grosso", sigla: "MT" },
        { nome: "Mato Grosso do Sul", sigla: "MS" },
      ],
      Sudeste: [
        { nome: "Espírito Santo", sigla: "ES" },
        { nome: "Minas Gerais", sigla: "MG" },
        { nome: "Rio de Janeiro", sigla: "RJ" },
        { nome: "São Paulo", sigla: "SP" },
      ],
      Sul: [
        { nome: "Paraná", sigla: "PR" },
        { nome: "Rio Grande do Sul", sigla: "RS" },
        { nome: "Santa Catarina", sigla: "SC" },
      ],
    },
  }
};

export default fixedInputData;
