import gql from 'graphql-tag'
export const GET_FORMATS = gql`
query Formats($filter: String, $limit: Int, $offset: Int, $direction: String, $orderby: String) {
  formats(filter: $filter, limit: $limit, offset: $offset, direction: $direction, orderby: $orderby) {
    site_id
    format_id
    format_name
    format_size_height
    format_size_width
  }
}`
export const GET_TAGS = gql`
query Query {
  tags {
    tag_id
    tag_code
  }
}`