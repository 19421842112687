import gql from 'graphql-tag'
export const UPDATE_CAMPAIGN = gql`
mutation Mutation($content: CampaignContent!, $changeUrlForAll: Boolean, $changeDateForAll: Boolean, $changeRegionForAll: Boolean, $applyToAllCampaignDeliveryLimitRatio: Boolean) {
  updateCampaign(content: $content, change_url_for_all: $changeUrlForAll, change_date_for_all: $changeDateForAll, change_region_for_all: $changeRegionForAll, apply_to_all_campaign_delivery_limit_ratio: $applyToAllCampaignDeliveryLimitRatio) {
    code
    data
    message
    success
  }
}`
