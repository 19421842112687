import gql from 'graphql-tag'
export const COPY_CAMPAIGN = gql`
mutation CopyCampaign($content: CampaignCopyContent!) {
  copyCampaign(content: $content) {
    code
    data
    message
    success
  }
}`
